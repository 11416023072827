import React from "react";
import "./App.scss";
 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";

import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./page/Home/Home";
import Products from "./page/Products/Products";
import Product from "./page/Product/Product";
import Almond from "./page/Almond/Almond";
import Coconut from "./page/Almond/Coconut";
import Nut_Butters from "./page/NutButter/Nut_Butters";
import Chocolate from "./page/NutButter/Chocolate";
import Contact from "./page/Contact/Contact";
import About from "./page/About/About";
import Conditions from "./page/t&c/Conditions";
import PrivacyPolicy from "./page/Policy/PrivacyPolicy";
import Succsess from "./components/payment/Succsess";
import Failed from "./components/payment/Failed";
import Navbar from "./components/Navbar/Navbar";
import Whatapp from "./components/whatapp/Whatapp";
import Footer from "./components/Footer/Footer";
import Error from "./page/Error/Error";
import Offer from "./page/offer/Offer";
import Payment from "./page/checkOut/Payment";
import axios from "axios";
import Loading from "./components/payment/Loading";

// axios.defaults.baseURL = "https://payment.notionsgroup.com/api/v1";
axios.defaults.baseURL = "https://api-master.notionsgroup.com/api/v1";
// axios.defaults.baseURL = "http://localhost:4343/api/v1";
axios.defaults.withCredentials = true;


// pages for the router

const Layout = () => {
  return (
    <div className="app">
      <Navbar />
      <Whatapp />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },
      {
        path: "/product/:id",
        element: <Product />,
      },
      {
        path: "/offers",
        element: <Offer />,
      },
      {
        path: "/coconut-sugar",
        element: <Coconut />,
      },
      {
        path: "/nut-butter",
        element: <Nut_Butters />,
      },
      {
        path: "/chocolate-chips",
        element: <Chocolate />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },

      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/terms-&-conditions",
        element: <Conditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/succsess",
        element: <Succsess />,
      },
      {
        path: "/failed",
        element: <Failed />,
      },
      {
        path: "*",
        element: <Error />,
      },
      {
        path: "/mail",
        element: <Error />,
      },
      {
        path: "/check_out",
        element: <Payment />,
      },
      {
        path: "/loadding",
        element: <Loading />,
      },
    ],
  },
]);

//main component

const App = () => {
  return (
    <div>
      <ToastContainer />
      <RouterProvider router={router} />{" "}
    </div>
  );
};

export default App;

import "./FeaturedProducts.scss";
import Card from "../../components/Card/Card";
import useFetch from "../../hooks/useFetch";

const FeaturedProducts = ({ type }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  // Sort data by price in descending order (high to low)
  const sortedData = data
    ?.slice()
    .sort((b, a) => b.attributes.price - a.attributes.price);

  return (
    <div className="max-width">
      <div className="featuredProducts">
        <div className="bottom" >
          {error
            ? "Something went wrong!"
            : loading
            ? "Loading..."
            : sortedData?.map((item) => <Card item={item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;

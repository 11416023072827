import React from 'react'
import "./loading.scss";

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading_wrp">
        <div className="loading_img">
          <img src={require("../../img/loader.gif")} alt="" />
        </div>
        <p>
          Please wait.... <br />
          Do not close the window until your order <br /> has been successfully
          created.
        </p>
      </div>
    </div>
  );
}

export default Loading
